import axios from "axios";

const apiClient = axios.create({ baseURL: process.env.NEXT_PUBLIC_API_BASE_URL });

apiClient.interceptors.response.use(originalResponse => {
  handleDates(originalResponse.data);
  return originalResponse;
});

const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?$/;

function isIsoDateString(value: any): boolean {
  return value && typeof value === "string" && isoDateFormat.test(value);
}

export function handleDates(body: any) {
  if (body === null || body === undefined || typeof body !== "object")
    return body;

  for (const key of Object.keys(body)) {
    const value = body[key];
    if (isIsoDateString(value)) body[key] = new Date(value);
    else if (typeof value === "object") handleDates(value);
  }
}

export default apiClient;
