import { IPalette } from "@fluentui/style-utilities";

export const greenTheme = {
  light: {
    palette: {
      themePrimary: "#128712",
      themeLighterAlt: "#f1faf1",
      themeLighter: "#cbeccb",
      themeLight: "#a2dba2",
      themeTertiary: "#58b758",
      themeSecondary: "#239623",
      themeDarkAlt: "#107a10",
      themeDark: "#0d670d",
      themeDarker: "#0a4c0a",
      neutralLighterAlt: "#faf9f8",
      neutralLighter: "#f3f2f1",
      neutralLight: "#edebe9",
      neutralQuaternaryAlt: "#e1dfdd",
      neutralQuaternary: "#d0d0d0",
      neutralTertiaryAlt: "#c8c6c4",
      neutralTertiary: "#a19f9d",
      neutralSecondary: "#605e5c",
      neutralPrimaryAlt: "#3b3a39",
      neutralPrimary: "#323130",
      neutralDark: "#201f1e",
      black: "#000000",
      white: "#ffffff",
    } as IPalette,
  },
  dark: {
    palette: {
      themePrimary: "#0d8d0d",
      themeLighterAlt: "#f3fcf3",
      themeLighter: "#d0f4d0",
      themeLight: "#aaebaa",
      themeTertiary: "#5fd75f",
      themeSecondary: "#24c524",
      themeDarkAlt: "#0caa0c",
      themeDark: "#0a8f0a",
      themeDarker: "#076a07",
      neutralLighterAlt: "#323232",
      neutralLighter: "#818181",
      neutralLight: "#2f2f2f",
      neutralQuaternaryAlt: "#2c2c2c",
      neutralQuaternary: "#2a2a2a",
      neutralTertiaryAlt: "#282828",
      neutralTertiary: "#c8c8c8",
      neutralSecondary: "#d0d0d0",
      neutralPrimaryAlt: "#dadada",
      neutralPrimary: "#ffffff",
      neutralDark: "#f4f4f4",
      black: "#f8f8f8",
      white: "#333333",
    } as IPalette,
  },
};

export const turquoiseTheme = {
  light: {
    palette: {
      themePrimary: "#007e91",
      themeLighterAlt: "#f1f9fb",
      themeLighter: "#c7e8ed",
      themeLight: "#9bd5de",
      themeTertiary: "#4caebd",
      themeSecondary: "#138c9f",
      themeDarkAlt: "#007183",
      themeDark: "#00606e",
      themeDarker: "#004751",
      neutralLighterAlt: "#f8f8f8",
      neutralLighter: "#f4f4f4",
      neutralLight: "#eaeaea",
      neutralQuaternaryAlt: "#dadada",
      neutralQuaternary: "#d0d0d0",
      neutralTertiaryAlt: "#c8c8c8",
      neutralTertiary: "#595959",
      neutralSecondary: "#373737",
      neutralPrimaryAlt: "#2f2f2f",
      neutralPrimary: "#000000",
      neutralDark: "#151515",
      black: "#0b0b0b",
      white: "#ffffff",
    } as IPalette,
  },
  dark: {
    palette: {
      themePrimary: "#00ced1",
      themeLighterAlt: "#000808",
      themeLighter: "#002121",
      themeLight: "#003e3f",
      themeTertiary: "#007b7d",
      themeSecondary: "#00b5b8",
      themeDarkAlt: "#15d2d6",
      themeDark: "#35d9dc",
      themeDarker: "#65e3e5",
      neutralLighterAlt: "#323232",
      neutralLighter: "#666666",
      neutralLight: "#2f2f2f",
      neutralQuaternaryAlt: "#2c2c2c",
      neutralQuaternary: "#2a2a2a",
      neutralTertiaryAlt: "#282828",
      neutralTertiary: "#fafafa",
      neutralSecondary: "#fbfbfb",
      neutralPrimaryAlt: "#fcfcfc",
      neutralPrimary: "#f8f8f8",
      neutralDark: "#fdfdfd",
      black: "#fefefe",
      white: "#333333",
    } as IPalette,
  },
};
export const blueTheme = {
  light: {
    palette: {
      themePrimary: "#121278",
      themeLighterAlt: "#f1f1fa",
      themeLighter: "#cacae9",
      themeLight: "#a0a0d6",
      themeTertiary: "#5555ae",
      themeSecondary: "#222288",
      themeDarkAlt: "#10106c",
      themeDark: "#0e0e5b",
      themeDarker: "#0a0a43",
      neutralLighterAlt: "#f8f8f8",
      neutralLighter: "#f4f4f4",
      neutralLight: "#eaeaea",
      neutralQuaternaryAlt: "#dadada",
      neutralQuaternary: "#d0d0d0",
      neutralTertiaryAlt: "#c8c8c8",
      neutralTertiary: "#595959",
      neutralSecondary: "#373737",
      neutralPrimaryAlt: "#2f2f2f",
      neutralPrimary: "#000000",
      neutralDark: "#151515",
      black: "#0b0b0b",
      white: "#ffffff",
    } as IPalette,
  },
  dark: {
    palette: {
      themePrimary: "#3a6aff",
      themeLighterAlt: "#02040a",
      themeLighter: "#091129",
      themeLight: "#12204d",
      themeTertiary: "#234199",
      themeSecondary: "#345fe0",
      themeDarkAlt: "#4e7aff",
      themeDark: "#6a8fff",
      themeDarker: "#91adff",
      neutralLighterAlt: "#0b0b0b",
      neutralLighter: "#151515",
      neutralLight: "#252525",
      neutralQuaternaryAlt: "#2f2f2f",
      neutralQuaternary: "#373737",
      neutralTertiaryAlt: "#595959",
      neutralTertiary: "#a6a6a6",
      neutralSecondary: "#8b8b8b",
      neutralPrimaryAlt: "#6f6f6f",
      neutralPrimary: "#f8f8f8",
      neutralDark: "#393939",
      black: "#1e1e1e",
      white: "#000000",
    } as IPalette,
  },
};
